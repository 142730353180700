import * as React from "react";
import {useQuery} from '@apollo/client'
import {
  GetQuotesRecent, meInfo_meInfo, onAddedQuote
} from "./graphql/generated/typed";
import {getQuotesRecent, quotesSubscription} from "./graphql/quotes";
import {Link} from "react-router-dom";
import 'moment-timezone';
import { store } from 'react-notifications-component';
import {useEffect} from "react";

const EmptyRender = (props:{doSubscription: () => void}) => {
  useEffect(() => {
    props.doSubscription();
  },
    // eslint-disable-next-line
    []);
  return <div />;
};

const BackgroundSubscriptions = (props: {
  meInfo: meInfo_meInfo,
}) => {
  const {loading, error, subscribeToMore} = useQuery<GetQuotesRecent>(getQuotesRecent);
  if (error) { console.error('subscription error', error); return <div />; }
  if (loading) return <div />;

  return <EmptyRender doSubscription={() => {
    subscribeToMore<onAddedQuote>({
      document: quotesSubscription,
      variables: {userId: props.meInfo.id},
      updateQuery: (prev, {subscriptionData}) => {
        if (!subscriptionData.data || !subscriptionData.data.addedQuote) return prev;

        const quoteId = subscriptionData.data.addedQuote.id;
        console.log("received new quote via subscription", subscriptionData.data);
        store.addNotification({
          title: `New Quote ${quoteId}`,
          message: <div>
            <Link to={`/quote/${quoteId}`}>View {subscriptionData.data.addedQuote.customer.name}</Link>
          </div>,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 15000,
            onScreen: true,
            showIcon: true
          }
        });

        return Object.assign({}, prev, {
          getQuotesRecent: [subscriptionData.data.addedQuote, ...prev.getQuotesRecent]
        });
      }
    });
  }} />;
};
export default BackgroundSubscriptions;

