import * as React from "react";
import "./Home.css";

export default class Home extends React.Component {
  render() {
    return (
        <div className="Home">
          <div className="lander">
            <h1>BIA</h1>
            <p>Build it awesome</p>
          </div>
        </div>
    );
  }
}
