import * as React from "react";
import * as queryString from "querystring";
import {useMutation} from '@apollo/client';
import {addGrant3LOMutation} from "../graphql/grant3lo";
import {addGrant3LO, addGrant3LOVariables, meInfo_meInfo, ProviderCode} from "../graphql/generated/typed";
import {useEffect, useState} from "react";
import {validateOAuthStateParam} from "../components/OAuthLinks";
import {userSetupQuery} from "../graphql/webhooks";

const OAuthCallback = ({meInfo, location, history}:{
  meInfo: meInfo_meInfo | null,
  location: {
    search: string
    state: string
  },
  history: any,
}) => {
  const [addFn, {loading, data}] = useMutation<addGrant3LO, addGrant3LOVariables>(addGrant3LOMutation);
  const [err, setErr] = useState<string|null>();
  useEffect(() => {
    // Wait for user info to populate before executing mutation
    if (!meInfo) { return; }

    // Extract code + state from query string
    const params = queryString.parse(removeQuestion(location.search));
    if (!params.code) {
      setErr("Authorization failed (code not present).  Please try again.")
      return;
    }
    const {code, state} = params;
    const codeStr = code as string; // parameters can be either string or string[]
    const stateStr = state as string; // parameters can be either string or string[]

    // Validate the state param from before the OAuth dance started
    const providerCode = validateOAuthStateParam(stateStr);
    if (!providerCode) {
      setErr("Unable to validate granting access.  Please try again.");
      return;
    }

    // do mutation
    addFn({
      variables: {providerCode: providerCode as ProviderCode, code: codeStr},
      refetchQueries: [
        {query: userSetupQuery},
      ]
    })
      .then(res => {
        console.log('response looks successful', res);

        window.setTimeout(() => {
          history.push('/usersetup');
        }, 750);
      })
      .catch(err => {
        console.error(err);
        setErr(`Unable to authorize: ${err.toString()}`)
      });
  },
    // eslint-disable-next-line
    [meInfo]);


  return (
    <div>
      <h3>OAuth Authorize Callback</h3>
      {loading && <div>Processing...</div>}
      {err && <div style={{color: 'red'}}>{err}</div>}
      {data && data.addGrant3LO.provider.code && <div style={{color: 'green'}}>Success.  Redirecting...</div>}
    </div>
  );
};

export default OAuthCallback;

const removeQuestion = (qs:string) => {
  if (qs.startsWith('?')) {
    return qs.substring(1);
  }
  return qs;
};
