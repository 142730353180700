import React from "react";
import { Button } from "reactstrap";
import "./LoaderButton.css";

export default ({
                  isLoading = false,
                  text = "",
                  loadingText = "Loading...",
                  className = "",
                  disabled = false,
                  ...props
                }) =>
    <Button
        className={`LoaderButton ${className}`}
        disabled={disabled || isLoading}
        {...props}
    >
      {isLoading && <div>loading</div>}
      {!isLoading ? text : loadingText}
    </Button>;
