import * as React from 'react';
import {useEffect, useState} from "react";
import Nav from "reactstrap/lib/Nav";
import Navbar from "reactstrap/lib/Navbar";
import NavItem from "reactstrap/lib/NavItem";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownItem from "reactstrap/lib/DropdownItem";
import NavLink from "reactstrap/lib/NavLink";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import {Link} from "react-router-dom";
import {meInfo_meInfo_tenant} from "./graphql/generated/typed";
import {waitForGoogleSDKAuth2Init} from "./components/LoginGoogleButton";
import {FaUser} from "react-icons/fa";

interface NavProps {
  isAuthenticated: boolean,
  isAdmin: boolean|null,
  tenant: meInfo_meInfo_tenant|null,
  onLogout: () => void,
}
const MyNav = (props:NavProps) => {
  return (
    <Navbar color="light" light expand="md">
      <div className="navbar-brand">
        <Link to={"/"}>
          <LogoImage logoImage={props.tenant && props.tenant.logoImage} />
        </Link>
      </div>
      <Nav className="ml-auto" navbar>
        {props.isAuthenticated
          ?
          <>
            <NavItem>
              <NavLink tag={Link} to="/quotes/recent">Recent Quotes</NavLink>
            </NavItem>
          </>
          :
          <NavItem>
            <NavLink tag={Link} to="/login">Login</NavLink>
          </NavItem>
        }

        {props.isAuthenticated && <AuthenticatedUserDropdown {...props}/>}
      </Nav>
    </Navbar>
  );
};

const LogoImage = ({logoImage}:{logoImage:string|null}) => {
  if (!logoImage) { return <span />; }
  return <img alt="Logo" src={logoImage} />;
};

type GoogleProfile = null|{
  getImageUrl: () => string,
  getName: () => string,
};
const AuthenticatedUserDropdown = (props:NavProps) => {
  const [isOpen, setOpen] = useState(false);
  const [profile, setProfile] = useState<GoogleProfile>(null);
  useEffect(() => {
    waitForGoogleSDKAuth2Init()
      .then(() => {
        const authInstance = window.gapi.auth2.getAuthInstance();
        if (authInstance && authInstance.currentUser.get() && authInstance.currentUser.get().getBasicProfile()) {
          setProfile(authInstance.currentUser.get().getBasicProfile());
          return;
        }
        setProfile(null);
      });
  });

  return (
    <Dropdown nav isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
      <DropdownToggle className="dropdown-avatar" nav caret>
        <UserAvatar profile={profile} />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem href="#" onClick={() => props.onLogout()}>Logout</DropdownItem>
        <DropdownItem tag={Link} to="/usersetup">User Setup</DropdownItem>

        {props.isAdmin && <>
          <DropdownItem header>All Users</DropdownItem>
          <DropdownItem tag={Link} to="/quotes/recent/allUsers">All Recent Quotes</DropdownItem>

          <DropdownItem header>Global Setup</DropdownItem>
          <DropdownItem tag={Link} to="/globalsetup/parts">Parts</DropdownItem>
          <DropdownItem tag={Link} to="/globalsetup/categories">Categories</DropdownItem>

          <DropdownItem header>Advanced</DropdownItem>
          <DropdownItem tag={Link} to="/globalsetup/importparts">Import Parts</DropdownItem>
          <DropdownItem tag={Link} to="/globalsetup/kcdexport">KCD Export</DropdownItem>
          <DropdownItem tag={Link} to="/globalsetup/docusignconfig">Docusign Config</DropdownItem>
        </>}
      </DropdownMenu>
    </Dropdown>

  );
};

const UserAvatar = ({profile}:{profile:GoogleProfile}) => {
  if (!profile)
    return <FaUser className="rounded-circle avatar-unknown"/>;

  return <img className="rounded-circle avatar" src={profile.getImageUrl()} alt={`Signed in as ${profile.getName()}`} />;
};

export default MyNav;
