import React, {Suspense} from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AppliedRoute from "./components/AppliedRoute";
import OAuthCallback from "./containers/OAuthCallback";
import {meInfo_meInfo} from "./graphql/generated/typed";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const QuotesRecent = React.lazy(() => import ("./containers/quotes/QuotesRecent"));
const Quote = React.lazy(() => import ("./containers/quotes/Quote"));

const UserSetup = React.lazy(() => import ("./containers/usersetup/UserSetup"));

const Parts = React.lazy(() => import ("./containers/globalsetup/Parts"));
const Categories = React.lazy(() => import ("./containers/globalsetup/Categories"));
const ImportParts = React.lazy(() => import ("./containers/globalsetup/ImportParts"));
const KCDExportSetup = React.lazy(() => import ("./containers/globalsetup/KCDExportSetup"));
const DocusignConfig = React.lazy(() => import ("./containers/globalsetup/DocusignConfig"));

export default ({ isAuthenticated, setAuthenticated, meInfo }:{
  isAuthenticated: boolean,
  setAuthenticated: (_:boolean) => void,
  meInfo: meInfo_meInfo | null,
}) => {
  const defProps = {
    isAuthenticated,
    meInfo,
  };

  return (
    <div>
      <Suspense fallback={<div style={{textAlign: 'center', marginTop: '1em'}}><FontAwesomeIcon icon={faSpinner} spin size="3x" /></div>}>
        <Switch>
          <AppliedRoute path="/" exact component={Home} props={defProps} />
          <AppliedRoute path="/login" exact component={Login} props={{...defProps, setAuthenticated}}/>

          <AppliedRoute path="/usersetup" exact component={UserSetup} props={defProps} />
          <AppliedRoute path="/globalsetup/parts" exact component={Parts} props={defProps} />
          <AppliedRoute path="/globalsetup/categories" exact component={Categories} props={defProps} />
          <AppliedRoute path="/globalsetup/importparts" exact component={ImportParts} props={defProps} />
          <AppliedRoute path="/globalsetup/kcdexport" exact component={KCDExportSetup} props={defProps} />
          <AppliedRoute path="/globalsetup/docusignconfig" exact component={DocusignConfig} props={defProps} />

          <AppliedRoute path="/oauthcallback" exact component={OAuthCallback} props={defProps} />

          <AppliedRoute path="/quotes/recent" exact component={QuotesRecent} props={defProps} />
          <AppliedRoute path="/quotes/recent/allUsers" exact component={QuotesRecent} props={{...defProps, allUsers: true}}/>
          <AppliedRoute path="/quote/:id" exact component={Quote} props={defProps} />

          {/* Finally, catch all unmatched routes */}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </div>
  );
}

