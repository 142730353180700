import gql from "graphql-tag";

export const addGrant3LOMutation = gql`
    mutation addGrant3LO($providerCode:ProviderCode!, $code:String!) {
        addGrant3LO(providerCode:$providerCode, code:$code) {
            provider {
                id
                code
            }
        }
    }
`;

export const getProviderQuery = gql`
    query getProvider($code:ProviderCode!) {
        getProviders(code:$code) {
            id
            code
            clientId
            redirectURI
        }
    }
`;
