import gql from "graphql-tag";

export const userSetupQuery = gql`
    query UserSetupQuery {
        getWebhooks {
            __typename
            id
            userId
            provider {
                __typename
                id
                code
            }
            url
            expiration
        }
        getGrants3LO {
            __typename
            id
            provider {
                __typename
                id
                code
            }
        }
        getProviders {
            __typename
            id
            code
            clientId
            redirectURI
        }
    }
`;

export const integrationUserSetupQuery = gql`
  query IntegrationUserSetupQuery($providerCode:ProviderCode!) {
      getWebhooks(providerCode:$providerCode) {
          __typename
          id
          expiration
      }
      getGrants3LO(providerCode:$providerCode) {
          __typename
          id
          provider {
              __typename
              id
              code
          }
      }
  }
`;

export const addWebhookMutation = gql`
    mutation AddWebhook($providerCode:ProviderCode!) {
        addWebhook(providerCode:$providerCode) {
            __typename
            id
            userId
            provider {
                __typename
                id
                code
            }
            url
            expiration
        }
    }
`;
export const removeWebhookMutation = gql`
    mutation RemoveWebhook($providerCode:ProviderCode!) {
        removeWebhook(providerCode:$providerCode)
    }
`;

