import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";

const googleLoginImg = require('./btn_google_signin_dark_normal_web@2x.png');

declare global {
  interface Window { gapi: any; }
}

window.gapi = window.gapi || {};

function isFunction(functionToCheck) {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

function waitForGoogleSDKAvailable() {
  return new Promise((res) => {
    const hasGoogleLoaded = () => {
      if (window.gapi && isFunction(window.gapi.load)) {
        res();
      } else {
        setTimeout(hasGoogleLoaded, 300);
      }
    };
    hasGoogleLoaded();
  });
}
export async function waitForGoogleSDKAuth2Init () {
  return new Promise(async (res) => {
    await waitForGoogleSDKAvailable();

    window.gapi.load('auth2', function () {
      // Need to call init() so that the global window.gapi.auth2.{abc} functions are available globally.
      // This allows aws-amplify to access to the window.gapi.auth2 object and refresh access tokens on our behalf.
      // Note that this also unblocks running JS in the console, like:
      //  window.gapi.auth2.getAuthInstance().then(googleAuth => console.log(googleAuth.currentUser.get().isSignedIn()));
      window.gapi.auth2.init({client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID});
      res();
    });
  });
};

interface IProps {
  scope: string,
  onLogin(data:{token:string, expires_at:number, email:string}): Promise<boolean>
}
const LoginGoogleButton  = (props:IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string|null>(null);

  useEffect(() => {
    waitForGoogleSDKAuth2Init()
      .then(() => setIsLoading(false));
  });

  const handleClick = () => {
    const i = window.gapi.auth2.getAuthInstance();
    i.signIn({
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
      scope: props.scope,
      response_type: 'id_token permission token',
      hosted_domain: process.env.REACT_APP_GOOGLE_OAUTH_HOSTED_DOMAIN,
    })
      .then(googleUser => {
        const response = googleUser.getAuthResponse(true);
        const email = googleUser.getBasicProfile().getEmail();
        props.onLogin({
          token: response.id_token,
          expires_at: response.expires_at,
          email: email,
        })
          .then(() => setError(null))
          .catch(e => setError(e.toString()));
      })
      .catch(e => {
        console.error(e.error);
        setError(e.error.toString());
      });

  };

  const style = {width: '250px', height: 'auto'};
  return (
    <div>
      {error && <div style={{color: 'red'}}>{error}</div>}
      {!isLoading ? <img
        style={{...style, cursor: 'pointer'}}
        alt={"Login with Google"}
        src={googleLoginImg}
        onClick={handleClick}
      /> : <div><FontAwesomeIcon icon={faSpinner} spin size="2x" /></div>}
    </div>
  );
};
export default LoginGoogleButton;
