import * as React from "react";
import uuidv4 from 'uuid/v4';
import Button from "reactstrap/lib/Button";

const buildUrl = (url, params) => {
  const parts = Object.keys(params).map(key => {
    return `${key}=${encodeURIComponent(params[key])}`;
  });
  const combined = parts.join("&");
  return `${url}?${combined}`
};

export const LocalStorageKeyGoogle = "OAuthStateGoogle";
export const LocalStorageKeyDocusign= "OAuthStateDocusign";

const useStateWithLocalStorage = (localStorageKey, initialValue) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(localStorageKey) || initialValue,
  );
  React.useEffect(() => {
    localStorage.setItem(localStorageKey, value);
  }, [value, localStorageKey]);
  return [value, setValue];
};

// validateOAuthStateParam will check to see if provided OAuth redirect state param matches
// any of the stored ones in LocalStore before the OAuth dance triggered
export const validateOAuthStateParam = (state:string):string|null => {
  const stateGoogle = localStorage.getItem(LocalStorageKeyGoogle);
  if (state === stateGoogle) { return "google"; }

  const stateDocusign = localStorage.getItem(LocalStorageKeyDocusign);
  if (state === stateDocusign) { return "docusign"; }

  return null;
};

export const GoogleOAuthLink = () => {
  const [stateValue] = useStateWithLocalStorage(LocalStorageKeyGoogle, uuidv4());

  const onClick = () => {
    document.location.href = buildUrl(process.env.REACT_APP_GOOGLE_OAUTH_REQUEST_URI, {
      access_type: 'offline',
      scope: 'email profile https://www.googleapis.com/auth/drive.readonly',
      prompt: 'consent',
      response_type: 'code',
      client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECT_URI,
      hd: process.env.REACT_APP_GOOGLE_OAUTH_HOSTED_DOMAIN,
      state: stateValue,
    })
  };

  return (
    <Button
      onClick={() => onClick()}
      color="primary"
    >Setup</Button>
  );
};

export const DocusignOAuthLink = () => {
  const [stateValue] = useStateWithLocalStorage(LocalStorageKeyDocusign, uuidv4());

  const onClick = () => {
    document.location.href = buildUrl(process.env.REACT_APP_DOCUSIGN_OAUTH_REQUEST_URI, {
      response_type: 'code',
      scope: 'signature',
      client_id: process.env.REACT_APP_DOCUSIGN_OAUTH_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_DOCUSIGN_OAUTH_REDIRECT_URI,
      state: stateValue,
    })
  };

  return (
    <Button
      onClick={() => onClick()}
      color="primary"
    >Setup</Button>
  );
};

