import gql from "graphql-tag";

export const setUserEmail = gql`
    mutation setUserEmail($email:String!) {
        setUserAttributes(email:$email) {
            id
            email
        }
    }
`;

export const setUserDefinedAttributes = gql`
    mutation setUserDefinedAttributes(
      $license:String,
      $discountPercentDescription:String, 
      $discountPercent:Int, 
      $discountFlatDescription:String,
      $discountFlat:Float,
    ) {
        setUserAttributes(
            license:$license,
            discountPercentDescription:$discountPercentDescription,
            discountPercent:$discountPercent,
            discountFlatDescription:$discountFlatDescription, 
            discountFlat:$discountFlat,
        ) {
            id
            email
            license
            discount {
                percentDescription
                percent
                flatDescription
                flat
            }
        }
    }
`;

export const meInfoQuery = gql`
    query meInfo {
        meInfo {
            __typename
            id
            name
            email
            isAdmin
            license
            discount {
                percentDescription
                percent
                flatDescription
                flat
            }
            tenant {
                contact {
                    name
                    address
                    city
                    state
                    zip
                    phone
                }
                logoImage
            }
        }
    }
`;

