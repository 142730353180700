import * as React from "react";
import {Mutation} from '@apollo/client/react/components';
import {FormEvent} from "react";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { Auth } from "aws-amplify";
import LoginGoogleButton from "../components/LoginGoogleButton";
import {RouteComponentProps} from "react-router";
import {FormGroup, Input, Label} from "reactstrap";
import {setUserEmail} from "../graphql/user";

interface IState {
  isLoading: boolean
  email: string
  password: string
}

interface IProps{
  setAuthenticated(result:boolean): void
}

export default class Login extends React.Component<IProps & RouteComponentProps<{}>, IState> {
  public state:IState = {
    isLoading: false,
    email: "",
    password: ""
  };

  validateForm():boolean {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = (event:FormEvent):void => {
    let target = event.target as HTMLInputElement;
    switch(target.id) {
      case "email":
        this.setState({email: target.value});
        return;
      case "password":
        this.setState({password: target.value});
        return;
      default:
        throw new Error("unknown target");
    }
  };

  handleSubmit = async (event:FormEvent) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.setAuthenticated(true);
      this.props.history.push("/");
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  handleGoogleLogin = (data:{token:string, expires_at:number, email:string }) => {
    const {token, expires_at, email} = data;
    return Auth.federatedSignIn(
        'google',
        { token, expires_at },
        { name: email, email }
    ).then(cr => {
      console.log('done with federated signin, cred:', cr);
      this.props.setAuthenticated(true);
      this.props.history.push("/");
      return {email};
    });
  };

  // Not used currently.  Only using login with google.
  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label>Email</Label>
          <Input
          autoFocus
          type="email"
          value={this.state.email}
          onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input
          value={this.state.password}
          onChange={this.handleChange}
          type="password"
            />
            </FormGroup>
            <LoaderButton
          disabled={!this.validateForm()}
          isLoading={this.state.isLoading}
          text="Login"
          loadingText="Logging in…"
            />
      </form>
    );
  }

  render() {
    return (
      <Mutation mutation={setUserEmail}>
        {(setUserEmailFn) => (
          <div className="Login text-center">
            <LoginGoogleButton
              onLogin={credData => {
                return this.handleGoogleLogin(credData)
                  .then(data => {
                    console.log("Will push user attributes to graphql server", data);
                    return setUserEmailFn({variables: {email: data.email}})
                  })
                  .then(res => {
                    console.log("Push user attributes response: ", res);
                    return true;
                  })
              }}
              scope={'email profile'}
            />
          </div>
        )}
      </Mutation>
    );
  }
}
