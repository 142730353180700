import * as React from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import "./App.css";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import Nav from "./Nav";
import {useQuery} from '@apollo/client'
import {meInfoQuery} from "./graphql/user";
import {meInfo} from "./graphql/generated/typed";
import {waitForGoogleSDKAuth2Init} from "./components/LoginGoogleButton";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import BackgroundSubscriptions from "./BackgroundSubscriptions";


interface IProps {
  history: any
}
interface IState {
  isAuthenticated: boolean
  isAuthenticating: boolean
}

class App extends React.Component<IProps & RouteComponentProps<{}>, IState> {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
  };
  interval;

  async componentDidMount() {
    const tryAuth = async () => {
      try {
        await waitForGoogleSDKAuth2Init();
        await Auth.currentAuthenticatedUser();
        if (!this.state.isAuthenticated || this.state.isAuthenticating) {
          this.setState({isAuthenticated: true, isAuthenticating: false});
        }
      } catch (e) {
        if (e.toString() !== "not authenticated") {
          console.warn("Error during auth: ", e);
        }
        if (this.state.isAuthenticated || this.state.isAuthenticating) {
          this.setState({isAuthenticated: false, isAuthenticating: false});
        }
      }
    };

    // Try to authenticate when component first loads
    tryAuth();
    // Also since we're having some Amplify issues forcing logout in the background, let's refresh in the background every once in a while to
    // make sure we're actually still logged in.  Sometimes we're logged out in the background and not properly handling the error.
    // If we're no longer logged in, we want to update the UI properly.
    this.interval = setInterval(async () => {
      if (this.state.isAuthenticated) {
        await tryAuth();
      }
    }, 5000);
  };

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  handleLogout = async () => {
    await Auth.signOut();
    this.setState({isAuthenticated: false});
    this.props.history.push("/login");
  };

  render() {
    return (
      <AppDisplay
        isAuthenticated={this.state.isAuthenticated}
        setAuthenticated={isAuth => this.setState({isAuthenticated: isAuth})}
        onLogout={this.handleLogout}
      />
    );
  }
}

const AppDisplay = (props:{
  isAuthenticated: boolean,
  setAuthenticated: (hasAuth:boolean) => void
  onLogout: () => void,
}) => {
  const {data, error} = useQuery<meInfo>(meInfoQuery, {skip: !props.isAuthenticated});
  if (error) {
    console.error("Error querying meInfo: ", error);
  }

  return (
    <div className="App container">
      <ReactNotification />
      {data && data.meInfo && props.isAuthenticated && <BackgroundSubscriptions
        meInfo={data.meInfo}
      />}
      <Nav
        isAuthenticated={props.isAuthenticated}
        onLogout={() => props.onLogout()}
        isAdmin={(data && data.meInfo && data.meInfo.isAdmin) || null}
        tenant={(data && data.meInfo && data.meInfo.tenant) || null}
      />
      <Routes
        isAuthenticated={props.isAuthenticated}
        setAuthenticated={props.setAuthenticated}
        meInfo={(data && data.meInfo) || null}
      />
    </div>
  );
};

export default withRouter(App);
